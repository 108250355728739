




























































































































import VueBase from '../../../VueBase'
import { Component, Prop } from 'vue-property-decorator'
import { formatTimestamp } from '@/utils/utils'
@Component({
  name: 'HookTable',
  filters: {
    formatTimestamp(date: number | any) {
      return formatTimestamp(date)
    },
  },
})
export default class HookTable extends VueBase {
  @Prop({ default: '0', type: String }) ruleType!: string
  @Prop({ default: '1', type: String }) projectId!: string
  hookDialog = false
  hookType = {
    type: '1',
    name: '',
    short_name: '',
    enable: 1,
  }
  title = '新增'

  types = []
  multipleSelection = []
  handleSelectionChange(val: any) {
    this.multipleSelection = val
    // console.log('this.multipleSelection', this.multipleSelection)
  }
  recoForm = {
    id: 0,
    type: 0,
    prefix: '',
  }
  tableData = []
  pageSize = 20
  currentPage = 1
  total = 0

  async editRow(row: any) {
    this.title = '编辑'
    this.recoForm.id = row.pk
    this.hookDialog = true
    this.recoForm.prefix = row.rule_detail
  }

  async changeStatusBatch(op: string) {
    let str = ''
    switch (op) {
      case 'delete':
        str = this.$t('views.hookPage.del') as string
        break
      case 'enable':
        str = this.$t('views.hookPage.on') as string
        break
      case 'disable':
        str = this.$t('views.hookPage.off') as string
        break
    }
    this.$confirm(
      `${this.$t('views.hookPage.changeOne')}${str}${this.$t(
        'views.hookPage.changeTwo'
      )}`,
      this.$t('views.hookPage.pop') as string,
      {
        confirmButtonText: this.$t('views.hookPage.enter') as string,
        cancelButtonText: this.$t('views.hookPage.clear') as string,
        type: 'warning',
      }
    )
      .then(async () => {
        let obj: any = {
          delete_type: 2,
          project_id: Number(this.projectId),
          rule_type: Number(this.ruleType),
        }
        const ids = this.multipleSelection.map((item: any) => item.pk)
        if (this.multipleSelection.length !== 0) {
          obj.delete_ids = ids
        } else {
          this.currentPage = 1
          obj.delete_type = 1
        }
        const { status, msg } = await this.services.project.deleteRecognizeRule(
          obj
        )
        if (status !== 201) {
          this.$message({
            type: 'error',
            message: msg,
            showClose: true,
          })
          return
        }
        this.$message({
          type: 'success',
          message: msg,
          showClose: true,
        })
        await this.getTable()
      })
      .catch((err) => {
        console.log(err)
      })
  }

  async changeStatus(row: any, status = '') {
    this.loadingStart()
    const obj = await this.services.project.deleteRecognizeRule({
      delete_ids: [row.pk - 0],
      delete_type: 2,
      rule_type: Number(this.ruleType),
      project_id: Number(this.projectId),
    })
    this.loadingDone()
    if (obj.status !== 201) {
      this.$message({
        showClose: true,
        message: obj.msg,
        type: 'error',
      })
      return
    }
    await this.getTable()
  }

  clearHook() {
    this.recoForm = {
      id: 0,
      type: Number(this.ruleType),
      prefix: '',
    }
    this.hookDialog = false
  }
  fmtParams(arr: any) {
    let pStr = ''
    let str = ''
    arr.forEach((item: any) => {
      if (item.origin === 'P' && !pStr) {
        pStr += `${item.relation}${item.origin}${item.param}`
      } else if (item.origin === 'P' && pStr) {
        pStr += `,${item.param}`
      } else {
        str += `${item.relation}${item.origin}`
      }
    })
    return str + pStr
  }
  async enterHook() {
    if (this.recoForm.id) {
      this.loadingStart()
      console.log(this.recoForm)
      const { status, msg } = await this.services.project.putRecognizeRuleById({
        rule_id: this.recoForm.id,
        rule_type: Number(this.ruleType),
        project_id: Number(this.projectId),
        rule_detail: this.recoForm.prefix,
      })

      this.loadingDone()
      if (status !== 201) {
        this.$message({
          type: 'error',
          message: msg,
          showClose: true,
        })
        return
      }
      await this.getTable()
      this.clearHook()
    } else {
      this.loadingStart()
      const { status, msg } = await this.services.project.postRecognizeRule({
        rule_type: Number(this.ruleType),
        project_id: Number(this.projectId),
        rule_detail: this.recoForm.prefix,
      })

      this.loadingDone()
      if (status !== 201) {
        this.$message({
          type: 'error',
          message: msg,
          showClose: true,
        })
        return
      }
      await this.getTable()
      this.clearHook()
    }
  }
  async getTable() {
    this.loadingStart()
    const { status, msg, data, page } =
      await this.services.project.getRecognizeRule({
        page: this.currentPage,
        pageSize: this.pageSize,
        rule_type: Number(this.ruleType),
        project_id: Number(this.projectId),
      })
    this.loadingDone()
    if (status !== 201) {
      this.$message({
        type: 'error',
        message: msg,
        showClose: true,
      })
      return
    }
    this.total = page.alltotal
    if (data.length === 0 && this.currentPage > 1) {
      this.currentPage--
      await this.getTable()
      return
    }
    this.tableData = data
  }
  handleSizeChange(val: number) {
    this.pageSize = val
    this.currentPage = 1
    this.getTable()
  }
  handleCurrentChange(val: number) {
    this.currentPage = val
    this.getTable()
  }
  created() {
    this.getTable()
  }
}
