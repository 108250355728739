






















































































































































































































































































































































































































import VueBase from '../../VueBase'
import { Component } from 'vue-property-decorator'
import { Form } from 'element-ui'
import HookTable from '@/views/project/components/hookTable.vue'

@Component({
  name: 'ProjectEdit',
  components: {
    HookTable,
  },
})
export default class ProjectEdit extends VueBase {
  private prjectEditMenu = [
    {
      id: 0,
      name: '基础设置',
      children: [],
    },
    {
      id: 1,
      name: 'Server设置',
      open: false,
      children: [
        {
          f: 1,
          fname: 'Server设置',
          id: 2,
          name: '用户代码识别',
        },
      ],
    },
  ]
  private newEdit = false
  private changeMenu = '基础设置'
  private type = '1'
  private advanced = false
  private departmentList = []
  private radio = ''
  private submitForm: {
    name: string
    mode: string
    agentIdList: Array<number>
    version_name: string
    description: string
    vul_validation: number
    base_url: string
    test_req_header_key: string
    test_req_header_value: string
    log_level: any
    enable_log: any
  } = {
    name: '',
    mode: this.$t('views.projectEdit.mode1') as string,
    agentIdList: [],
    version_name: '',
    description: '',
    vul_validation: 0,
    base_url: '',
    test_req_header_key: '',
    test_req_header_value: '',
    log_level: '',
    enable_log: '',
  }
  private engineList: Array<{
    id: number
    short_name: string
    token: string
  }> = []
  private engineSelectedList: Array<{
    id: number
    token: string
  }> = []
  private strategyList: Array<{ id: number; name: string }> = []
  private strategyTypeList: Array<{
    level_id: number
    level_name: number
    type_value: Array<{
      strategy_id: number
      level_id: number
      state: string
      vul_name: string
    }>
  }> = []
  private rules = {
    name: [
      {
        required: true,
        message: this.$t('views.projectEdit.namePlaceholder'),
        trigger: 'blur',
      },
    ],
  }

  private scanAddDialogOpen = false
  private scanForm: {
    ids: Array<number>
    name: string
    fid: number
  } = {
    ids: [],
    fid: 1,
    name: '',
  }
  private isSelectAll = false
  private Hopen(i: any) {
    i.open = !i.open
    let arr = i.children.map((item: any) => {
      return item.name
    })
    console.log(arr)
    if (arr.includes(this.changeMenu)) {
      return
    }
    // this.changeMenu = i.children[0].name
  }
  private async getListDepartment() {
    // 部门list
    const res = await this.services.deploy.getDepartment({})
    if (res.status === 201) {
      this.departmentList = res.data
      return
    }
    this.$message.error(res.msg)
  }
  async created() {
    if (this.$route.params.pid) {
      this.newEdit = true
    }
    await this.getEngineList()
    await this.getListDepartment()
    await this.strategyUserList()
    if (this.$route.params.pid) {
      await this.projectDetail()
      await this.getHeaderId()
    }
    this.changeMenu = '基础设置'
  }

  private scanAddDialogShow() {
    this.scanAddDialogOpen = true
    this.scanForm = {
      ids: [],
      fid: 1,
      name: '',
    }
    this.strategyTypes()
  }

  private async projectDetail() {
    const { status, msg, data } = await this.services.project.projectDetail(
      this.$route.params.pid
    )
    if (status !== 201) {
      this.$message({
        type: 'error',
        message: msg,
        showClose: true,
      })
      return
    }

    if (!this.strategyList.some((item) => data.scan_id === item.id)) {
      this.strategyList.push({ id: data.scan_id, name: data.scan_name })
    }

    this.submitForm.name = data.name
    this.submitForm.mode = data.mode
    this.submitForm.agentIdList = data.agents.map((item: { id: any }) => {
      return item.id
    })
    this.submitForm.version_name = data.versionData?.version_name
    this.submitForm.description = data.versionData?.description
    this.submitForm.vul_validation = data.vul_validation
    this.submitForm.base_url = data.base_url
    this.submitForm.test_req_header_key = data.test_req_header_key
    this.submitForm.test_req_header_value = data.test_req_header_value
    this.submitForm.enable_log = data.enable_log
    this.submitForm.log_level = data.log_level
    this.agentChange()
  }

  private async getEngineList() {
    const idParams = this.$route.params.pid || '0'
    const { status, msg, data } = await this.services.project.getEngineList(
      idParams
    )
    if (status !== 201) {
      this.$message({
        type: 'error',
        message: msg,
        showClose: true,
      })
      return
    }
    this.engineList = data
  }
  private selectAllChange() {
    const idSet = new Set([...this.scanForm.ids])
    this.strategyTypeList[
      this.strategyTypeList.findIndex(
        (i: { level_id: number }) => i.level_id === this.scanForm.fid
      )
    ].type_value.forEach(
      (item: {
        strategy_id: number
        level_id: number
        state: string
        vul_name: string
      }) => {
        if (this.isSelectAll) {
          idSet.add(item.strategy_id)
        } else {
          idSet.delete(item.strategy_id)
        }
      }
    )
    this.scanForm.ids = [...idSet]
  }
  private checkIdChange(id: number) {
    const idSet = new Set([...this.scanForm.ids])
    if (idSet.has(id)) {
      idSet.delete(id)
    } else {
      idSet.add(id)
    }
    this.scanForm.ids = [...idSet]
    this.isAllSelect()
  }

  isAllSelect() {
    const idSet = new Set([...this.scanForm.ids])
    this.isSelectAll = this.strategyTypeList[
      this.strategyTypeList.findIndex(
        (i: { level_id: number }) => i.level_id === this.scanForm.fid
      )
    ].type_value.every(
      (item: {
        strategy_id: number
        level_id: number
        state: string
        vul_name: string
      }) => {
        return idSet.has(item.strategy_id)
      }
    )
  }

  private async strategyUserList() {
    this.loadingStart()
    const { status, msg, data } = await this.services.setting.strategyUserList()
    this.loadingDone()
    if (status !== 201) {
      this.$message({
        type: 'error',
        message: msg,
        showClose: true,
      })
      return
    }
    this.strategyList = data
  }

  private async strategyTypes() {
    this.loadingStart()
    const { status, msg, data } = await this.services.setting.strategyTypes()
    this.loadingDone()
    if (status !== 201) {
      this.$message({
        type: 'error',
        message: msg,
        showClose: true,
      })
      return
    }
    data
      .reduce(
        (
          list: any[],
          item: {
            level_id: number
            level_name: number
            type_value: Array<{
              strategy_id: number
              level_id: number
              state: string
              vul_name: string
            }>
          }
        ) => {
          list = [...list, ...item.type_value]
          return list
        },
        []
      )
      .forEach(
        (item: {
          strategy_id: number
          level_id: number
          state: string
          vul_name: string
        }) => {
          if (item.state === 'enable') {
            this.scanForm.ids.push(item.strategy_id)
          }
        }
      )
    this.strategyTypeList = data
  }

  private async strategyUserAdd() {
    const params = {
      ids: this.scanForm.ids.join(','),
      name: this.scanForm.name,
    }
    this.loadingStart()
    const { status, msg, data } = await this.services.setting.strategyUserAdd(
      params
    )
    this.loadingDone()
    if (status !== 201) {
      this.$message({
        type: 'error',
        message: msg,
        showClose: true,
      })
      return
    }
    this.scanAddDialogOpen = false
    this.strategyUserList()
  }

  private agentChange() {
    this.engineSelectedList = this.engineList.reduce(
      (
        list: Array<{ id: number; token: string }>,
        item: { id: number; token: string }
      ) => {
        if (this.submitForm.agentIdList.includes(item.id)) {
          list.push({ ...item })
        }
        return list
      },
      []
    )
  }

  private idDelete(index: number) {
    this.submitForm.agentIdList = [
      ...this.submitForm.agentIdList.slice(0, index),
      ...this.submitForm.agentIdList.slice(index + 1),
    ]
    this.agentChange()
  }

  private projectAdd() {
    ;(this.$refs.submitForm as Form).validate(async (valid: any) => {
      if (valid) {
        const params: {
          name: string
          mode: string | any
          agent_ids: string
          pid?: string
          version_name: string | undefined
          description: string | undefined
          vul_validation: number
          base_url: string
          test_req_header_key: string
          test_req_header_value: string
          enable_log: any
          log_level: any
        } = {
          name: this.submitForm.name,
          mode: this.submitForm.mode,
          agent_ids: this.submitForm.agentIdList.join(','),
          version_name: this.submitForm.version_name
            ? this.submitForm.version_name
            : undefined,
          description: this.submitForm.description
            ? this.submitForm.description
            : undefined,
          vul_validation: this.submitForm.vul_validation,
          base_url: this.submitForm.base_url,
          test_req_header_key: this.submitForm.test_req_header_key,
          test_req_header_value: this.submitForm.test_req_header_value,
          enable_log: this.submitForm.enable_log,
          log_level: this.submitForm.log_level,
        }
        if (this.$route.params.pid) {
          params.pid = this.$route.params.pid
        }
        const { status, msg } = await this.services.project.projectAdd(params)
        if (status !== 201) {
          this.$message({
            type: 'error',
            message: msg,
            showClose: true,
          })
          return
        }
        this.$message({
          type: 'success',
          message: msg,
          showClose: true,
        })
        await this.$router.go(-1)
      } else {
        console.log('error submit!!')
        return false
      }
    })
  }

  private header_id = []
  private async getHeaderId() {
    const res = await this.services.project.req_headers({
      id: this.$route.params.pid,
    })
    if (res.status === 201) {
      this.header_id = res.data
    }
  }
}
